@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"














































































































































































































































































































































































































































































































































































































































































































.assessemnt__warning
  margin-top: 16px

  .sas-info__label
    text-transform: none
    margin: 0
    color: #343C58
    font-size: 12px

  .badge
    margin-top: 32px
    background: transparentize($color-danger, .6) !important

    +mq-s
      margin-top: 24px

  .info
    grid-gap: 0px

    .info-right  p
      margin-left: 12px
      text-align: left

    .info-left .icon
      width: 16px
      height: 16px

.assessment__exams
  padding: 32px 0px 16px 0px
  width: 100%
  box-sizing: border-box
  display: grid
  grid-gap: 24px
  grid-template-columns: repeat(3, 1fr)
  padding-bottom: 48px

  +mq-l
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 16px

  +mq-s
    padding-top: 24px
    grid-template-columns: 100%

  .exam
    min-height: 200px

  .exam__badge
    margin-top: 16px

  .exam__details
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: flex-start
    height: 100%

    &__infos
      margin: 16px 0

      &__label
        margin-bottom: 16px

        .sas-info__label
          text-transform: none
          font-size: 16px
          margin: 0
          color: #666E75 !important
          margin-left: $size-xs

    &.--flex-start
     justify-content: flex-start

    &.--exam-done
      height: auto

    .info
      margin-bottom: 16px
      background: white
      border-radius: 4px

  .exam__warning
    margin-top: 26px

    .info-right__label
      font-size: 14px

    .sas-info__label
      text-transform: none
      margin: 0

  .box__footer
    display: flex
    justify-content: space-between
    width: 100%

  .sas-dropdown
    width: 115px
    height: 44px
    margin-right: 5px

  .sas-button.--secondary
    height: 100%

